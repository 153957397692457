// Here you can add other styles
/*Custom pagination*/
.pagination {
  display: inline-block;
  padding-left: 0;
  margin: 20px 0;
  border-radius: 4px;
  li{
    display: inline;
    a, span{
      display: inline-block;
      cursor: pointer;
      padding: .35rem .9rem;
      font-size: 12px;
      border: 1px solid #eee;
      border-radius: 2px;
      margin-right: 4px;
      color: #15092d !important;
    }
  }
  .active {
    a, span{
      background: #15092d;
      color: #fff !important;
    }
  }
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  text-decoration: none;
}

.pagination li a:hover {
  text-decoration: none;
}

.font-Lg {
  font-size: 3.5rem;
}

.card-number {
  font-size: 3.5rem;
}

.card-text {
  font-size: 1.5rem;
}

body{
  font-family: 'Rubik', sans-serif;
  cursor: pointer
}

/*
** Landing Page
**/
.landing-container{
  background: rgb(18,9,35);
  background: radial-gradient(circle, rgb(176, 140, 11) 0%, rgba(18,9,35,1) 86%);
  //background: -webkit-gradient(linear, left top, left bottom, from(rgba(18, 9, 35, 0.97)),
  //        to(rgba(208, 164, 18, 0.97))), url('airline.png') no-repeat;
;
}
.dashboard-container{
  margin: 25px 200px 0;
  & a{
    text-decoration: none;
  }

}
.dashboard-item{
  background: #fff;
  padding: 25px 20px;
  margin: 0 0 20px;
  border-left: solid 6px #9f7c47;
  //border-radius: 0 0 10px 10px;
  transition: all 5s ease-in-out;

  &:hover{
     /*transition: all .4s ease;*/
    //zoom: 1.2;
    & .icons{
      color: #9f7c47
    }
    & button {
      background: #9f7c47;
      border-color: #9f7c47;
      &:hover{
        background: #9f7c47;
        border-color: #9f7c47;
      }
    }
   }
  & .title{
    //color: #9f7c47;
  }
  & button{
    background: #15092d;
    border-color: #15092d;
    &:hover{
      background: #120923;
      border-color: #120923;
    }
  }
  & .icons{
    margin: 0;
    text-align: center;
    font-size: 70px;
  }
}

.login-container {
  & .card{
    border-radius: 0;
    border: none !important;
  }
  background: url("airline.png") center no-repeat #11122a21;
  background-size: cover;
  &:before{
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    background: rgba(29, 9, 45, 0.8);
    content: "";
  }
  & .content{
    z-index: 1;
    border-radius: 0;
  }
}
.login-bg-container{
  background: url("loginBg.jpg") center no-repeat;
  background-size: cover;
  position: relative;
  &:before{
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    background: rgba(29, 9, 45, 0.5);
    content: "";
  }
  & .content{
    z-index: 1;
    border-radius: 0;
  }

}
.login-section{
  box-shadow: 4px 4px 10px 2px rgba(0, 0, 0, 0.28);
  border-radius: 0 !important;
}

.circularProgress {
       text-align: center;
       margin-top: 15%;
       background: linear-gradient(rgba(21,9,45, 0.3), rgba(21,9,45, 0.1));
       border-radius: 50%;
         color: #15092d;
}

.passenger-card {
  background: linear-gradient(rgba(251,215,11, 0.8), rgba(251,215,11, 0.4));
    color: #15092d;
}

.flight-card {
  background: linear-gradient(rgba(21,9,45, 1), rgba(21,9,45, 0.7));
  color: #fff;
}

.sidebar-fixed .app-header+.app-body .sidebar {
  background: linear-gradient(rgba(21,9,45, 1), rgba(21,9,45, 0.7));
  color: #fff;
  //font-size: 15px
}

.sidebar .nav-link.active {
  background: linear-gradient(rgba(251,215,11, 0.8), rgba(251,215,11, 0.4));
  color: #15092d;
}

.sidebar .nav-link .nav-icon {
  color: #fff;
  margin-right: 10px;
  font-size: 0.975rem;
}
.sidebar-minimized .sidebar .nav-item:hover > .nav-link{
  background: linear-gradient(rgba(251,215,11, 0.8), rgba(251,215,11, 0.4));
  color: #15092d;

}
